/* Stil für große Bildschirme (breiter als 600px) */
@media screen and (min-width: 600px) {
    .responsive-container {
        width: 80%;
        margin: auto; /* Zentriert den Container */
    }
}

/* Stil für kleine Bildschirme */
@media screen and (max-width: 599px) {
    .responsive-container {
        width: 100%;
        margin: auto;
    }
}

/* Allgemeine Layout-Stile */
.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.section {
    width: 100%;
    max-width: 800px; /* Erhöht die maximale Breite der Sektionen */
    margin: 30px auto;
}